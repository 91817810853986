<div class="card border-dark mx-auto my-1">
    <div class="card-body">
        <div class="row">
            <div class="col-10">
                <h4 *ngIf="project.url; else nonLink" class="card-title"><a class="card-link" href="{{project.url}}">{{project.title}}</a><span class="mx-3 my-auto link-icon" style="font-size:medium;"><a href="{{project.url}}"><i class="fa fa-external-link" aria-hidden="true"></i></a></span></h4>
                <ng-template #nonLink>  
                    <h4 class="card-title">{{project.title}}</h4>    
                </ng-template>
            </div>
            <div class="col-2 text-right">
                <a class="navbar-brand text-dark" href="{{project.repo}}"><i class="fa fa-github" aria-hidden="true"></i></a>
            </div>
        </div>
        <p class="card-text my-2">{{project.description}}</p>
        <span *ngFor="let tool of project.stack" [ngClass]="getClass(tool)" class="badge mx-1">
            <span *ngFor="let c of toList(tool)">{{c}}</span>
        </span>
    </div>
</div>