// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    type: "service_account",
    project_id: "calejc-46fcd",
    private_key_id: "0915473d022825516451ec32d115c251bbaec43e",
    private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDzGFdEeGlQ2kkz\n3h24tSfNo+2iZIvWukILSNijtyWZPPVZUAlE10ujDlcITa3UPfklPb2HThCuVaiY\nmUWNQ8Rge+BrA9MLkDtSNfU9/olitAwkA7cTJBxMqJZn7iY4vBubvuI92ufa1Vr5\n8SksJfC2XQT18d3o2y19ONXFcM9ncDf4OijNGBCaexbySieQYGUzs84AFLCCRicw\nzm8mgxmmJPUnwVyNtp+kdX1RFvLATphnxr9snQ8DtOeq8PKgT5sfwbh6/DcKeYMV\nwCZs2fr9NUdRJptazu4Isn180XNAY/bAu8PgV0eZa+A7ZVSf9eVdkh2GWr7U/ND6\nENxKIfnjAgMBAAECggEADsJckgiphEIsjZyfu1YsW3zncaOfJeNbHSpIFPCKtNoR\nn2GPnoFf0z+MozFsK2Wz2cH83tkrhBopSVKM+X2yz5TV1EmAJ8STBXPHNwNcPgTm\nc81R8TSRD0pxD1BTO8CYAIW/YLgIua2Yf3JX6JlJGxlcsr4Dlh6wjVjFCd7GiOYW\nBNI2SX5RvkA2+CzRSEfEL2ZHPawZ8SjnCDwD2oj/8z9yQjZ8gzfqU0oEgSIc6JwP\nv0VAxFue/AobX5aZ1nwBDwCnUPVoImY9uL5mjd3sSxUC0Ew6evRJ3wUy62d6EnN4\n8O6uJLWARqtaKPfI6xXfHvBpwPsS/Piw7rWwxmgH0QKBgQD7ROCcKkCqCXYyS86j\nJynwcckpm6jXRu3im1WRdYCmr/iRISBhUBzy1SR9Cs2FgpIpkHqoDaKE8+R/krA7\nmZcpevuhvq6LclV3XpuviV28liGV9Z68GKyICV+eCWopyu6Ez9un5MJQSIlHpEzO\nheO0sTLg+PHRv/1Zs5sRilIz2wKBgQD3rBCVVzScYWlwXexWFNzeMkjrjZNP+4gf\nc8GP3yYwjJHRnXP2by1cDYJMDgQRYnmQJYguK+8QraD2juSvPGNrNZ3bcOrXa7k8\nvePZoUBFSVVS9EQTCdqLW5RfYTwV7cy56KogAs+DsKTHR2KFBDWw+Zt5+Di9NjeC\noyF6cxG0mQKBgQDJGt5+ft7UeNAt/zh6oqzRcfCkKZ6f9ngM53A8BIScZLkYRyAo\nYrtYq7kRj2Tg15luROG2wXry2FVouZnticdPfswJL5l5qF+5M3J9sv7gInTtFfIe\n78ye8Imj7jsXCgGPMOHIZbIUGYouWdTTcpvGEhD7uKOq0fPPKI4TItcaAwKBgQCg\nPQmppXcbagbOtWWvt+XCOGRnSfyyNbm3J6xmyegqZvf811LscLe3I+JWFIdbPAwM\nT8wQm0obz0oh2wdnvLlR5qmQDTlTUJXTD8Q3zJUJfs2eUv0sGCb2HLUdFIrMYUMg\nUMCo30ilGgcyCyRZjQSz0Hpf6lHDUS2O7r49O++LmQKBgQC9lxCiLwT9Ne0GM7aO\nQgtDjTi8hykYXDX81n64ixyz2JXQAfTGlXSin6KmQS02pVjTfOAUwOj+dkH8WwtK\nobAHjZmaP8q5xR72tohmxmiCzHc5gKUOe96M/0v/dmpdYl9NLr5P4oMVCLUvhq2S\nkVC70h0Y/fcct/cgR2gj97LuCA==\n-----END PRIVATE KEY-----\n",
    client_email: "firebase-adminsdk-poemw@calejc-46fcd.iam.gserviceaccount.com",
    client_id: "102217158000405077563",
    storageBucket: "calejc-46fcd.appspot.com",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    uth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-poemw%40calejc-46fcd.iam.gserviceaccount.com"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
