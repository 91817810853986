<div class="row mt-5 ">
    <div class="col-lg-4 col-md-4 col-sm-4 col-12 my-5">
        <div class="card border-dark">
            <div class="card-body">
                <h5 class="card-title">Cale Cortney</h5>
                <a class="navbar-brand text-dark" data-toggle="tooltip" data-placement="bottom" title="Github" href="https://github.com/calejc"><i class="fa fa-github" aria-hidden="true"></i></a>
                <a class="navbar-brand text-dark" data-toggle="tooltip" data-placement="bottom" title="LinkedIn" href="https://www.linkedin.com/in/cale-cortney-b78174204"><i class="fa fa-linkedin-square" aria-hidden="true"></i></a>
                <a class="navbar-brand text-dark" data-toggle="tooltip" data-placement="bottom" title="Email" href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%63%6A%63%40%6D%61%69%6C%62%6F%78%2E%6F%72%67"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                <a class="navbar-brand text-dark" data-toggle="tooltip" data-placement="bottom" title="AWS Transcript" target="_blank" href="https://www.certmetrics.com/amazon/public/transcript.aspx?transcript=S4FL6KR2K1QQQYWH"><span class="iconify" data-icon="fa-brands:aws" data-inline="false"></span></a>
                <a class="navbar-brand text-dark" data-toggle="tooltip" data-placement="bottom" title="Resume" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/calejc-46fcd.appspot.com/o/_resume_.pdf?alt=media&token=c3c0319c-9240-46a2-88d1-ab725245fefc"><i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
            <img class="card-img-bottom p-3 rounded" src="../../../assets/imgs/headshot.jpg" alt="Cale Cortney headshot">
        </div>
        <div class="card border-dark my-2">
            <div class="card-body">
                <h5 class="card-title">Skills and Compentencies</h5>
                <ul class="list-group list-group-flush">
                    <li *ngFor="let skill of skills" [skill]="skill" class="list-group-item">{{ skill }}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-12 mx-auto">
        <div class="row">
            <div class="col-lg-10 mx-auto text-center">
                <h2 class="header">Portfolio</h2>
            </div>
        </div>
        <app-card *ngFor="let project of projects" [project]="project"></app-card>
    </div>
</div>